var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('v-tabs',{attrs:{"centered":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-sc"}},[_vm._v("Correcciones de calificaciones")]),_c('v-tab',{attrs:{"href":"#tab-lde"}},[_vm._v("Extensión de perfiles")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-sc"}},[_c('v-card',{staticClass:"ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2",attrs:{"elevation":"2"}},[_c('v-card-text',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Solicitudes de corrección de calificaciones ")]),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","prepend-icon":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.requests.search),callback:function ($$v) {_vm.$set(_vm.requests, "search", $$v)},expression:"requests.search"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":_vm.requestStatuses.isLoading
                        ? 'Cargando estados'
                        : 'Estado de la solicitud',"loading":_vm.requestStatuses.isLoading,"disabled":!_vm.requestStatuses.data.length,"outlined":"","dense":"","hide-details":"","clearable":"","multiple":"","items":_vm.requestStatuses.data,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.requests.filters.request_status_id.length - 1)+" seleccionados) ")]):_vm._e()]}}]),model:{value:(_vm.requests.filters.request_status_id),callback:function ($$v) {_vm.$set(_vm.requests.filters, "request_status_id", $$v)},expression:"requests.filters.request_status_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.requests.headers,"items":_vm.filteredData,"search":_vm.requests.search,"items-per-page":10,"loading":_vm.requests.isLoading},scopedSlots:_vm._u([{key:`item.evaluation`,fn:function({ item }){return [_c('p',{staticClass:"m-0 my-1 text-wrap"},[_c('strong',[_vm._v(_vm._s(item.evaluation_type))]),_vm._v(" del "),_c('strong',[_vm._v(_vm._s(item.sub_evaluation_percent_formatted))]),_vm._v(" del perfil "),_c('strong',[_vm._v(_vm._s(item.evaluation))]),_vm._v(" de la asignatura "),_c('strong',[_vm._v(_vm._s(item.subject))])])]}},{key:`item.actions`,fn:function({ item }){return [(
                          _vm.existInArray(
                            'Ver solicitudes de correción de notas',
                            _vm.currentPageActions
                          )
                        )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"lime accent-4"},on:{"click":function($event){_vm.selectRequest(item);
                                _vm.showMdlRequestDetail();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-format-list-checks ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]:_vm._e()]}}],null,true)})],1)],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-lde"}},[_c('v-card',{staticClass:"ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2",attrs:{"elevation":"2"}},[_c('v-card-text',{staticClass:"text-h5 font-weight-medium"},[_vm._v(" Solicitudes de extensión de fecha límite de evaluaciones ")]),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"label":_vm.requestStatuses.isLoading
                        ? 'Cargando estados'
                        : 'Estado de la solicitud',"loading":_vm.requestStatuses.isLoading,"disabled":!_vm.requestStatuses.data.length,"outlined":"","dense":"","hide-details":"","clearable":"","multiple":"","items":_vm.requestStatuses.data,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.limitDateExtensions.filters.request_status_id .length - 1)+" seleccionados) ")]):_vm._e()]}}]),model:{value:(_vm.limitDateExtensions.filters.request_status_id),callback:function ($$v) {_vm.$set(_vm.limitDateExtensions.filters, "request_status_id", $$v)},expression:"limitDateExtensions.filters.request_status_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.limitDateExtensions.headers,"items":_vm.limitDateExtensionsFilteredData,"search":_vm.limitDateExtensions.search,"items-per-page":10,"loading":_vm.limitDateExtensions.isLoading},scopedSlots:_vm._u([{key:`item.evaluation`,fn:function({ item }){return [_c('p',{staticClass:"m-0 my-1 text-wrap"},[_c('strong',[_vm._v(_vm._s(item.evaluation_type))]),_vm._v(" del "),_c('strong',[_vm._v(_vm._s(item.sub_evaluation_percent_formatted))]),_vm._v(" del perfil "),_c('strong',[_vm._v(_vm._s(item.evaluation))]),_vm._v(" de la asignatura "),_c('strong',[_vm._v(_vm._s(item.subject))])])]}},{key:`item.actions`,fn:function({ item }){return [(
                          _vm.existInArray(
                            'Ver solicitudes de extensión de perfil',
                            _vm.currentPageActions
                          )
                        )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"lime accent-4"},on:{"click":function($event){_vm.selectLimitDateExtension(item);
                                _vm.showMdlLimitDateExtensionDetail();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-format-list-checks ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]:_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)]),_c('RequestDetailModal',{ref:"mdlRequestDetail",attrs:{"request":_vm.selectedRequest}}),_c('LimitDateExtensionDetailModal',{ref:"mdlLimitDateExtensionDetail",attrs:{"limitDateExtensionRequest":_vm.selectedLimitDateExtension}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Mis solicitudes "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Aqui se podrá visualizar la información de cada una de las solicitudes realizadas.")])])]),_c('div',{staticClass:"card-toolbar"})])
}]

export { render, staticRenderFns }