<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Mis solicitudes
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podrá visualizar la información de cada una de las
              solicitudes realizadas.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <v-tabs v-model="tab" centered grow>
          <v-tab href="#tab-sc">Correcciones de calificaciones</v-tab>
          <v-tab href="#tab-lde">Extensión de perfiles</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-sc">
            <!-- begin:: scores correction request list -->
            <v-card
              elevation="2"
              class="ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2"
            >
              <v-card-text class="text-h5 font-weight-medium">
                Solicitudes de corrección de calificaciones
              </v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      prepend-icon=""
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Buscar..."
                      v-model="requests.search"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      :label="
                        requestStatuses.isLoading
                          ? 'Cargando estados'
                          : 'Estado de la solicitud'
                      "
                      :loading="requestStatuses.isLoading"
                      :disabled="!requestStatuses.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="requestStatuses.data"
                      item-text="name"
                      item-value="id"
                      v-model="requests.filters.request_status_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{ requests.filters.request_status_id.length - 1 }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="requests.headers"
                      :items="filteredData"
                      :search="requests.search"
                      :items-per-page="10"
                      :loading="requests.isLoading"
                    >
                      <template v-slot:[`item.evaluation`]="{ item }">
                        <p class="m-0 my-1 text-wrap">
                          <strong>{{ item.evaluation_type }}</strong> del <strong>{{ item.sub_evaluation_percent_formatted }}</strong> del perfil <strong>{{ item.evaluation }}</strong> de la asignatura <strong>{{ item.subject }}</strong>
                        </p>
                      </template>
                      <!-- begin: Actions -->
                      <template v-slot:[`item.actions`]="{ item }">
                        <template
                          v-if="
                            existInArray(
                              'Ver solicitudes de correción de notas',
                              currentPageActions
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="
                                  selectRequest(item);
                                  showMdlRequestDetail();
                                "
                                color="lime accent-4"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-format-list-checks
                              </v-icon>
                            </template>
                            <span>Ver detalle</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <!-- end: Acions -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!-- end:: scores correction request list -->
          </v-tab-item>
          <v-tab-item value="tab-lde">
            <!-- begin:: sub evaluation limit date extension -->
            <v-card
              elevation="2"
              class="ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2"
            >
              <v-card-text class="text-h5 font-weight-medium">
                Solicitudes de extensión de fecha límite de evaluaciones
              </v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      :label="
                        requestStatuses.isLoading
                          ? 'Cargando estados'
                          : 'Estado de la solicitud'
                      "
                      :loading="requestStatuses.isLoading"
                      :disabled="!requestStatuses.data.length"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="requestStatuses.data"
                      item-text="name"
                      item-value="id"
                      v-model="limitDateExtensions.filters.request_status_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            limitDateExtensions.filters.request_status_id
                              .length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="limitDateExtensions.headers"
                      :items="limitDateExtensionsFilteredData"
                      :search="limitDateExtensions.search"
                      :items-per-page="10"
                      :loading="limitDateExtensions.isLoading"
                    >
                      <template v-slot:[`item.evaluation`]="{ item }">
                        <p class="m-0 my-1 text-wrap">
                          <strong>{{ item.evaluation_type }}</strong> del <strong>{{ item.sub_evaluation_percent_formatted }}</strong> del perfil <strong>{{ item.evaluation }}</strong> de la asignatura <strong>{{ item.subject }}</strong>
                        </p>
                      </template> 
                      <!-- begin: Actions -->

                      <template v-slot:[`item.actions`]="{ item }">
                        <template
                          v-if="
                            existInArray(
                              'Ver solicitudes de extensión de perfil',
                              currentPageActions
                            )
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="
                                  selectLimitDateExtension(item);
                                  showMdlLimitDateExtensionDetail();
                                "
                                color="lime accent-4"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-format-list-checks
                              </v-icon>
                            </template>
                            <span>Ver detalle</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <!-- end: Acions -->
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <!-- end:: sub evaluation limit date extension -->
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <RequestDetailModal
      ref="mdlRequestDetail"
      :request="selectedRequest"
    ></RequestDetailModal>
    <LimitDateExtensionDetailModal
      ref="mdlLimitDateExtensionDetail"
      :limitDateExtensionRequest="selectedLimitDateExtension"
    ></LimitDateExtensionDetailModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import requestStatusRepository from "@/repositories/requestStatusRepository";
import requestRepository from "@/repositories/requestRepository";
import limitDateExtensionRepository from "@/repositories/limitDateExtensionRepository";
import RequestDetailModal from "@/components/modals/requests/RequestDetailModal.vue";
import LimitDateExtensionDetailModal from "@/components/modals/requests/LimitDateExtensionDetailModal.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "MyRequests",
  title: "Mis solicitudes | GE ITR",
  components: {
    RequestDetailModal,
    LimitDateExtensionDetailModal,
  },
  data() {
    return {
      requests: {
        headers: [
          { text: "Razón de la solicitud", value: "request_reason" },
          { text: "Detalle de evaluación", value: "evaluation" },
          { text: "Estado de la solicitud", value: "request_status" },
          { text: "Fecha de solicitud", value: "created_at" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      limitDateExtensions: {
        headers: [
          { text: "Razón de la solicitud", value: "request_reason" },
          { text: "Detalle de evaluación", value: "evaluation" },
          { text: "Estado de la solicitud", value: "request_status" },
          { text: "Fecha de solicitud", value: "created_at" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      requestStatusColorById: {
        1: {
          color: "amber",
          icon: "clock",
        },
        2: {
          color: "success",
          icon: "check-circle",
        },
        3: {
          color: "red",
          icon: "close-circle",
        },
      },
      requestStatuses: { data: [], isLoading: false },
      selectedRequest: {},
      selectedLimitDateExtension: {},
      tab: null,
    };
  },
  props: {
    // Se recibe el id de la solicitud
    r: {
      type: String,
    },
    // Se recibe el id de la solicitud de extensión
    lde: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mis solicitudes", route: "my_requests" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Mis solicitudes");
    this.getRequestStatuses();
    this.loadRequests();
    this.loadLimitDateExtensionRequests();
  },
  methods: {
    loadRequests() {
      if (!this.currentUserPersonalInfo.id_user) {
        return;
      }
      this.requests.isLoading = true;
      // Si el id_user no esta setteado no se hace la petición
      requestRepository
        .getByUserId(this.currentUserPersonalInfo.id_user)
        .then((response) => {
          this.requests.data = response.data;
          if (this.r) {
            ApiService.get(`${this.baseApiUrl}/version/uu/${this.r}`).then(
              ({ data }) => {
                const item = this.requests.data.find((item) => item.id == data);
                this.selectRequest(item);
                this.showMdlRequestDetail();
                this.tab = "tab-sc";
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requests.isLoading = false;
        });
    },
    loadLimitDateExtensionRequests() {
      if (!this.currentUserPersonalInfo.id_user) {
        return;
      }
      this.limitDateExtensions.isLoading = true;
      // Si el id_user no esta setteado no se hace la petición
      limitDateExtensionRepository
        .getLimitDateExtensionRequestByUserId(
          this.currentUserPersonalInfo.id_user
        )
        .then((response) => {
          this.limitDateExtensions.data = response.data;
          if (this.lde) {
            ApiService.get(`${this.baseApiUrl}/version/uu/${this.lde}`).then(
              ({ data }) => {
                const item = this.limitDateExtensions.data.find(
                  (item) => item.id == data
                );
                this.selectLimitDateExtension(item);
                this.showMdlLimitDateExtensionDetail();
                this.tab = "tab-lde";
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.limitDateExtensions.isLoading = false;
        });
    },

    getRequestStatusColor(requestStatusId) {
      const defaultColor = "grey darken-1";

      return (
        this.requestStatusColorById[requestStatusId]?.color ?? defaultColor
      );
    },

    getRequestStatusIcon(requestStatusId) {
      const defaultIcon = "account";

      return this.requestStatusColorById[requestStatusId]?.icon ?? defaultIcon;
    },

    getRequestStatuses() {
      this.requestStatuses.isLoading = true;
      requestStatusRepository
        .getAllRequestStatuses()
        .then(({ data }) => {
          this.requestStatuses.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestStatuses.isLoading = false;
        });
    },
    selectRequest(obj) {
      // Creating a non reactive copie of data
      this.selectedRequest = { ...obj };
    },
    selectLimitDateExtension(obj) {
      // Creating a non reactive copie of data
      this.selectedLimitDateExtension = { ...obj };
    },
    showMdlRequestDetail() {
      this.$refs.mdlRequestDetail.toggleModal();
    },
    showMdlLimitDateExtensionDetail() {
      this.$refs.mdlLimitDateExtensionDetail.toggleModal();
    },
    showMdlOfLateArrivalRequestDetail() {
      this.$refs.mdlDeferredLateArrivalRequestDetail.toggleModal();
    },
    setTabByParams() {
      if ("lde" in this.$router.history.current.query) {
        this.tab = "tab-lde";
        return;
      }
      if ("r" in this.$router.history.current.query) {
        this.tab = "tab-sc";
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    filteredData() {
      return this.filterData(this.requests.filters, this.requests.data);
    },
    limitDateExtensionsFilteredData() {
      return this.filterData(
        this.limitDateExtensions.filters,
        this.limitDateExtensions.data
      );
    },
    baseApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL;
    },
    getUserId() {
      return this.currentUserPersonalInfo.id_user;
    },
  },
  watch: {
    r(newValue, oldValue) {
      if (newValue) {
        this.loadRequests();
      }
    },
    lde(newValue) {
      if (newValue) {
        this.loadLimitDateExtensionRequests();
      }
    },
    getUserId(newValue, oldValue) {
      // Si el id_user no tenia ningun valor se observa a cuando cambie y se  hace la peticón
      if (newValue) {
        this.loadRequests();
        this.loadLimitDateExtensionRequests();
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
